<template>
  <div class="basket__product-wrapper">
    <div class="basket__product mb-3">
      <div class="basket__product-img">
        <img v-if="item.product" :src="imgUrl" alt="product-image" />
        <img v-else src="@/assets/images/no-photo.jpeg" alt="No photo" />
      </div>
      <div class="basket__product-info">
        <h5 class="basket__product-info-title">{{ item.product.name }}</h5>
        <p class="basket__product-info-descr">
          {{ item.product.short_description }}
        </p>
        <div class="basket__product-quantity">
          <span @click="decrementProductsQuantity" class="minus mr-3">-</span>
          <input v-model.lazy="quantity" type="number" class="basket__product-quantity-input" />
          <!--          <span class="quantity"> {{ item.quantity }} </span>-->
          <span @click="incrementProductsQuantity" class="plus ml-3">+</span>
        </div>
        <span @click="deleteProductFromBasket" class="basket__product-delete">Удалить</span>
      </div>
      <div class="basket__product-price">{{ getProductsTotalPrice }} ₸</div>
    </div>
    <!--    <div class="basket__product-price basket__product-sm-price">{{ getProductsTotalPrice }} ₸</div>-->
  </div>
</template>

<script>
export default {
  name: 'BasketProduct',
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({
        product: {},
      }),
    },
  },

  data: () => ({
    quantity: 1,
    isComponentMounted: false,
  }),

  mounted() {
    this.quantity = this.item.quantity
    this.$nextTick(() => {
      this.isComponentMounted = true
    })
  },

  beforeUpdate() {
    this.isComponentMounted = false
    this.quantity = this.item.quantity
    this.$nextTick(() => {
      this.isComponentMounted = true
    })
  },

  computed: {
    getProductsTotalPrice() {
      const total = Number(this.item.quantity) * Number(this.item.product.price)
      return new Intl.NumberFormat('ru-RU', { style: 'decimal' }).format(total)
    },

    imgUrl() {
      if (!this.item.product?.images?.length) return false
      return this.item.product.images[0].path
    },
  },

  watch: {
    quantity(newVal) {
      if (!this.isComponentMounted) return
      if (!newVal) {
        return (this.quantity = this.item.quantity)
      }
      this.setProductsQuantity()
    },
  },

  methods: {
    deleteProductFromBasket() {
      this.$emit('delete', this.item.leftover_id)
    },

    incrementProductsQuantity() {
      this.$emit('increment', this.item.leftover_id)
    },

    decrementProductsQuantity() {
      this.$emit('decrement', this.item.leftover_id)
    },

    setProductsQuantity() {
      this.$emit('setQuantity', {
        leftover_id: this.item.leftover_id,
        quantity: this.quantity,
      })
    },
  },
}
</script>
